/* Основные стили контейнера с Grid */
.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 колонки по умолчанию */
    gap: 16px; /* Отступы между блоками */
    padding: 16px;
}

/* Базовые стили для колонок */
.col {
    /*background-color: lightblue;*/
    /*padding: 20px;*/
    /*text-align: center;*/
}

.home__card {
    color: var(--text-color);
    backdrop-filter: blur(6px);
    /*background-color: var(--ess-navigation-bg-color);*/
    height: 201px;
}

/* Для экранов шириной меньше 1200px (например, планшеты) */
@media (max-width: 2000px) {
    .container {
        grid-template-columns: repeat(2, 1fr); /* 2 колонки */
    }
}

/* Для экранов шириной меньше 768px (например, мобильные устройства) */
@media (max-width: 768px) {
    .container {
        grid-template-columns: 1fr; /* 1 колонка */
    }
}